//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import moment from 'moment';
import NoSleep from 'nosleep.js';
import { mapGetters, mapActions } from 'vuex';
import CodeSize from '@/components/spec/code-compose/child/CodeSize.vue';
import PreviewQRCode from '@/components/spec/code-compose/child/manual/modals/PreviewQRCode';
import PageHeader from '@/components/tools/PageHeaderCustom.vue';
import {
  dbLength,
  symSizeLength,
  buildCodeContent,
  subStringSpecialLangCase
} from '@/components/_util/encode';
import { AdvancedInfo, RichCodeItem, PlainCodeItem, BasicInfo } from './components/index.js';
import { getCodeStatusColorCSS, getCodeStatusName } from '@/components/_util/util';
import { LANGUAGE_OPTIONS, UV_TO_RAGT_LANG, RAGT_TO_UV_LANG } from '@/enum';
import { deepDiffMapper } from '@/utils/diff-objects';

const RAGT_SCRIPT_ID = 'ragt_editor_id';

export default {
  components: {
    PageHeader,
    CodeSize,
    PreviewQrcode: PreviewQRCode,
    BasicInfo,
    AdvancedInfo,
    RichCodeItem,
    PlainCodeItem,
    ImageReview: () => import('@/components/tools/ImageReview.vue'),
    TreeSelect: () => import('@/components/tools/TreeSelect.vue'),
    ChoosePageModal: () => import('../../codes/modal/ChoosePageModal.vue')
  },
  data() {
    return {
      selectedCode: null,

      page: {},
      loading: false,
      copying: false,
      title: '',
      size: '',
      ecLevel: '',
      codes: [],
      status: '',
      linkURL: '',
      error: '',
      codeDetail: null,
      audio: null,
      ispublic: false,
      publicLoading: false,
      noSleep: new NoSleep(),
      categoryId: null,
      contentPreview: '',
      previewCutPlainTextVisible: false,
      thumbnail: { url: '', alt: '' },
      action: null,
      versions: [],
      isOpenVersionModal: false,
      janCodes: [
        {
          id: 0,
          value: ''
        }
      ],
      tags: [],
      messages: [],
      choosePageModalVisible: false,
      pageChoosingActionType: '',
      createdDate: null,
      ragtKey: `ragt-compose-${Math.random(10)}`,
      visibleRagtEditor: false,
      simplifiedHTML: '',
      defaultCode: null,

      plainCodeContentModalVisible: false,
      plainCodeContentPreview: '',
      dictionary: []
    };
  },
  computed: {
    ...mapGetters(['currentLanguage']),
    ...mapGetters('user', ['userInfo']),
    ...mapGetters('page', ['pages', 'pageById']),
    ...mapGetters('project', ['projectById', 'projectsAndPages']),
    companyId() {
      return this.userInfo.company_id;
    },
    currentProject() {
      return this.projectById(this.projectId) || {};
    },
    codeId() {
      return this.$route.params.codeId;
    },
    pageId() {
      return this.$route.params.pageId;
    },
    projectId() {
      return this.$route.params.projectId;
    },
    deActiveButton() {
      return (
        this.codes.length < 1 ||
        !this.title ||
        (this.thumbnail?.url && !this.thumbnail?.alt) ||
        this.codes.some(({ text, lang }) => !text || !lang)
      );
    },
    validateTitle() {
      return this.title === '' ? 'error' : 'success';
    },
    isDraftStatus() {
      return ['DRAFT'].includes(this.status);
    },
    codePublished() {
      return this.codeDetail?.status == 'PUBLISHED' || false;
    },
    codeStatus() {
      return getCodeStatusName(this.codeDetail);
    },
    colorStatus() {
      return getCodeStatusColorCSS(this.status);
    },
    enumLangs() {
      return LANGUAGE_OPTIONS;
    },
    usedLangs() {
      return this.codes.reduce((acc, item) => acc.concat(item.lang), []) || [];
    },
    noUseLangs() {
      return Object.keys(this.enumLangs).filter(langKey => !this.usedLangs?.includes(langKey));
    },
    soundOptions() {
      return {
        lexicon: this.page.lexicon || [],
        engine: this.codeDetail?.speech?.engine || 'standard'
      };
    },
    ragtPros() {
      return JSON.stringify({
        skipSteps: JSON.stringify([0, 1]),
        locale: this.currentLanguage,
        lang: UV_TO_RAGT_LANG[this.selectedCode?.lang || 'eng'],
        ragtId: this.selectedCode?.ragt?.id || '',
        ragtKey: this.ragtKey,
        html: this.simplifiedHTML,
        ragtClientId: process.env.VUE_APP_RAGT_CLIENT_ID,
        sound: this.soundOptions || { lexicon: [], engine: 'standard' },
        showCloseBtn: true
      });
    },
    code() {
      const data = {
        title: this.title,
        size: this.size,
        ecLevel: this.ecLevel,
        linkURL: this.linkURL,
        codes: this.codes,
        categoryId: this.categoryId,
        thumbnail: this.thumbnail,
        janCodes: this.janCodes,
        tags: this.tags,
        createdDate:
          typeof this.createdDate == 'string' ? this.createdDate : this.createdDate.toISOString()
      };
      return data;
    },
    isDefaultDataChanged() {
      if (this.defaultCode) {
        const result = deepDiffMapper.isDiff(this.defaultCode, this.code);
        return !this.deActiveButton && result;
      }
      return false;
    }
  },
  async created() {
    await this.getData();
    this.loadRagtEditor();
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isDefaultDataChanged) {
      return next();
    }
    const that = this;
    if (this.$ls.get('confirm-leave')) {
      this.$ls.remove('confirm-leave');
      return next();
    }
    if (to.name !== 'code-detail') {
      return this.$confirm({
        title: that.$t('Are you sure to leave this code detail?'),
        content: that.$t('The changed content will not be save.'),
        cancelText: that.$t('No, cancel'),
        okText: that.$t('Ok'),
        okType: 'primary',
        onOk() {
          next();
        },
        onCancel() {},
        class: 'test',
        width: 433
      });
    }
  },

  beforeDestroy() {
    if (this.audio) {
      this.audio.pause();
    }
    this.noSleep.disable();
    this.destroyRagtEditor();
  },

  methods: {
    ...mapActions('code', ['updateCode']),
    ...mapActions('project', ['getProjectsAndPagesByCompany']),

    // TODO: Basic info's event
    changeTitle(value) {
      this.title = value;
    },
    changeCreatedDate(value) {
      this.createdDate = value;
    },
    changeCodeSize(value) {
      this.size = value;
      //check limit
      this.checkAndShowIfContentLimitCodes();
      // emit the array to be reactive
      this.codes.push({});
      this.codes.pop();
    },
    changeErrorEcLevel(value) {
      this.ecLevel = value;
    },
    changeCategory(categoryId) {
      this.categoryId = categoryId;
    },

    // TODO: Advanced info's event
    changeOriginalLink({ target }) {
      this.linkURL = target.value;
      // check limit content of all codes
      this.checkAndShowIfContentLimitCodes();
    },
    removeJanCode(janCodesRemoved) {
      this.janCodes = this.janCodes.reduce((arr, item) => {
        if (item.id !== janCodesRemoved.id) arr.push(item);
        return arr;
      }, []);
    },
    addJanCode() {
      const payload = {
        id: this.janCodes?.length || 0,
        value: ''
      };
      this.janCodes.push(payload);
    },
    changeThumbnail(newThumbnail) {
      this.thumbnail = { ...newThumbnail };
    },
    changeTags(tags) {
      this.tags = tags;
    },
    changeDictionary(value) {
      this.dictionary = value
    },

    // TODO: RichCodeItem & PlainCodeItem event
    changeCodeItemLang({ lang, id }) {
      const code = this.codes.find(code => code.id === id);
      code.lang = lang;
    },
    changeCodeItemContent({ id, content }) {
      const codeItem = this.codes.find(item => item.id === id);
      codeItem.text = content;
      codeItem.contentOverLimit = this.checkContentOverLimit(codeItem);
    },
    deleteCodeItem(id) {
      this.codes = this.codes.filter(item => item.id !== id);
      //check limit
      this.checkAndShowIfContentLimitCodes();
    },
    openRagtCodeItem(item) {
      this.selectedCode = {
        ...item,
        ...(!item?.ragt?.id && { ragt: { id: '', html: '' } })
      };
      this.visibleRagtEditor = true;
    },

    // TODO: PlainCodeItem's event
    changeSSMLContent({ id, content }) {
      const codeItem = this.codes.find(item => item.id === id);
      const ssml = { ...codeItem.ssml, text: content };
      codeItem.ssml = ssml;
    },
    enableSSML({ id, is_enable }) {
      const codeItem = this.codes.find(item => item.id === id);
      const ssml = { ...codeItem.ssml, is_enable };
      codeItem.ssml = ssml;
      // emit the array to be reactive
      this.codes.push({});
      this.codes.pop();
    },
    async handlePlaySpeech({ id, text, lang, ssml, type, gender }) {
      if (this.audio) {
        this.audio.pause();
        this.audio = null;
      }
      try {
        this.loading = true;
        const url = await this.getSynthesizeSpeech(text, lang, ssml, type, gender);
        this.codes = this.codes?.map(item => {
          item.isPlaySpeech = item.id === id;
          return item;
        });
        this.audio = new Audio(url);
        this.audio.play();
        this.noSleep.enable();
        this.audio.onended = this.handleEndSpeech;
      } catch (error) {
        this.$notification.error({
          message: this.$t(error.TypeError) || this.$t('Network error')
        });
      } finally {
        this.loading = false;
      }
    },
    handlePauseSpeech({ id }) {
      this.codes = this.codes.map(item => {
        if (item.id === id) {
          item.isPlaySpeech = false;
        }
        return item;
      });
      this.audio.pause();
      this.audio = null;
      this.noSleep.disable();
    },
    catchSSMLError(id, error) {
      const codeItem = this.codes.find(item => item.id === id);
      codeItem.ssmlError = error;
    },
    previewPlainCodeContent({ id }) {
      const codeItem = this.codes.find(item => item.id === id);
      const MAX_LEN =
        (symSizeLength(this.size) - dbLength(this.linkURL)) / this.codes.length -
        subStringSpecialLangCase(codeItem.lang, this.size);

      this.plainCodeContentPreview = buildCodeContent(
        codeItem.text,
        codeItem.lang,
        MAX_LEN
      ).replaceAll('\n', '<br />');
      this.plainCodeContentModalVisible = true;
    },

    // TODO: RichCodeItem's event
    confirmRevertPlainText(code) {
      const currentCode = this.codes.find(item => item.id == code?.id);
      if (currentCode) {
        const self = this;
        this.$confirm({
          width: '500px',
          content: () => (
            <div>
              <div style="margin-bottom: 12px; font-weight: bold;">
                {this.$t('Do you want to revert the previous version?')}
              </div>
              <div
                class="preview-container"
                style="padding: 12px;"
                domPropsInnerHTML={this.$options.filters.nl2br(currentCode.preText)}
              />
            </div>
          ),
          onOk() {
            currentCode.text = currentCode.preText;
            currentCode.contentOverLimit = self.checkContentOverLimit(code);
          },
          okText: 'Revert',
          cancelText: this.$t('Cancel'),
          onCancel() {
            console.log('Close');
          }
        });
      }
    },
    previewCutPlainTextByCodeId(id) {
      const codeItem = this.codes.find(item => item.id === id);
      const MAX_LEN =
        (symSizeLength(this.size) - dbLength(this.linkURL)) / this.codes.length -
        subStringSpecialLangCase(codeItem.lang, this.size);

      this.contentPreview = buildCodeContent(codeItem.text, codeItem.lang, MAX_LEN).replaceAll(
        '\n',
        '<br />'
      );
      this.previewCutPlainTextVisible = true;
    },
    openRagtEditor({ selectedCode, simplifiedHTML }) {
      this.simplifiedHTML = simplifiedHTML;
      this.selectedCode = selectedCode;
      this.visibleRagtEditor = true;
    },
    cancelRagtEditorModal() {
      this.selectedCode = null;
      this.visibleRagtEditor = false;
      this.simplifiedHTML = '';
    },
    async submitRagtEditor(event) {
      const { ragtId, ragtJson, ragtHTML, resolve, reject } = event?.detail;
      try {
        if (!this.title && ragtJson?.metaOpt?.title) {
          this.title = ragtJson.metaOpt.title;
        }

        if (ragtJson) {
          const res = await this.$s.api.ragtEditor.InsertOrUpdateRagtJson({
            id: ragtId,
            ragtJson,
            html: ragtHTML
          });
          const code = this.codes.find(item => item.id == this.selectedCode?.id);
          if (code) {
            // code.lang = RAGT_TO_UV_LANG[ragtJson?.metaOpt?.lang || 'ja'];
            code.ragt = { id: res.id, html: ragtHTML };
            code.preText = code.text;
            code.text = res.text;
            code.contentOverLimit = this.checkContentOverLimit(code);
          }
          resolve({ id: res.id });
        }
      } catch (error) {
        console.log(error);
        reject(error);
      }
    },

    // TODO: Init function
    async getData() {
      try {
        this.loading = true;
        this.page = this.pageById(this.pageId) || (await this.getPageById());
        this.getProjectsAndPagesByCompany(this.userInfo.company_id);
        const [data, versions, messages] = await Promise.all([
          this.$s.api.page.getCodeById(this.pageId, this.codeId),
          this.$s.api.code.getVersions({
            where: { code_id: this.codeId, status: 'PUBLISHED' },
            order: 'created DESC'
          }),
          this.$s.api.message.find(this.companyId, { filter: { where: { code_id: this.codeId } } })
        ]);
        this.messages = messages || [];
        this.versions = versions;
        this.codeDetail = data;
        this.title = data.title;
        this.size = data.sym_size;
        this.ecLevel = data.sym_ec_level;
        this.linkURL = data.linkURL || '';
        this.createdDate = moment(data.created);
        const newCodes = data.codes.reduce((acc, item, index) => {
          item.id = index + 1;
          item.isPlaySpeech = false;
          item.contentOverLimit = false;
          item.ssml = item.ssml || {
            text: '',
            is_enable: false
          };
          return acc.concat(item);
        }, []);
        this.codes = newCodes;
        this.status = data.status;
        this.ispublic = data.ispublic;
        this.categoryId = data.category_id;
        // check limit content of all codes
        this.checkAndShowIfContentLimitCodes();
        this.thumbnail = data.thumbnail || { url: '', alt: '' };
        this.action = data.action;
        // jan code: |1234|2342|
        let rawJanCode = data.jan_codes
          ? data.jan_codes
              .substring(1)
              .slice(0, -1)
              .split('|')
          : [''];
        this.janCodes = rawJanCode.reduce((arr, item, index) => {
          arr.push({
            id: index,
            value: item
          });
          return arr;
        }, []);
        this.tags = data.tags || [];
        this.dictionary = data.dictionary || []
        this.error = data.error;

        // init default data
        this.defaultCode = JSON.parse(
          JSON.stringify({
            title: this.title,
            size: this.size,
            ecLevel: this.ecLevel,
            linkURL: this.linkURL,
            codes: this.codes,
            categoryId: this.categoryId,
            thumbnail: this.thumbnail,
            janCodes: this.janCodes,
            createdDate: this.createdDate
          })
        );
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },
    loadRagtEditor() {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = RAGT_SCRIPT_ID;
      script.src = `${process.env.VUE_APP_RAGT_EDITOR_URL}?v=${Math.random()}`;
      document.head.appendChild(script);
    },

    // TODO: Destroy function
    destroyRagtEditor() {
      document.head?.removeChild(document?.getElementById(RAGT_SCRIPT_ID));
    },

    // TODO: Others
    async getPageById() {
      try {
        const page = await this.$s.api.page.getPageById(this.projectId, this.pageId);
        return page;
      } catch (error) {
        console.log(error);
      }
    },
    addCode() {
      const id = this.codes.length + 1;
      const newItem = {
        id,
        lang: this.noUseLangs[0],
        text: '',
        ssml: {
          text: '',
          is_enable: false
        },
        ragt: { id: '', html: '' },
        isPlaySpeech: false,
        contentOverLimit: false
      };
      this.codes = [...this.codes, newItem];
      //check limit
      this.checkAndShowIfContentLimitCodes();
    },
    handleEndSpeech() {
      this.codes = this.codes.map(item => {
        item.isPlaySpeech = false;
        return item;
      });
      this.noSleep.disable();
    },
    regenerateCode() {
      if (this.status === 'FAILED') {
        this.status = 'GENERATE';
        if (this.action === 'EDITED') this.status = 'PUBLISHED';
      }
      this.editCode(this.status);
    },
    publishCode() {
      const status = 'GENERATE';
      this.editCode(status);
    },
    saveDraft() {
      const status = 'DRAFT';
      this.editCode(status);
    },
    async editCode(status) {
      this.$ls.set('confirm-leave', true);
      try {
        this.loading = true;
        // validate ssml content
        for (let i = 0; i < this.codes.length; i++) {
          let item = this.codes[i];
          if (item.ssml.is_enable && item.ssmlError?.error) {
            throw new Error(item.ssmlError.message);
          }
        }
        let jan_codes = [];
        this.janCodes.forEach(item => {
          if (item.value) jan_codes.push(item.value);
        });
        const input = {
          status: status,
          codes: JSON.parse(JSON.stringify(this.codes)).map(item => {
            item.id = item.id.toString();
            if (!item.ssml?.text && !item.ssml?.is_enable) {
              delete item.ssml;
            }
            delete item.isPlaySpeech;
            delete item.contentOverLimit;
            delete item.ssmlError;
            delete item.preText;
            return item;
          }),
          title: this.title,
          code_type: 'voice_code',
          sym_size: this.size,
          sym_ec_level: this.ecLevel,
          category_id: this.categoryId,
          linkURL: this.linkURL,
          thumbnail: this.thumbnail,
          jan_codes,
          tags: this.tags || [],
          dictionary: this.dictionary || [],
          created: moment(this.createdDate)
        };
        await this.$s.api.page.editCodeById(this.pageId, this.codeId, input);
        this.notify('success', {
          message:
            status === 'DRAFT' ? this.$t('SaveDraftSuccess') : this.$t('SaveGenerateSuccess'),
          description: `${this.$t('Code name')}: ${this.title}`
        });
        this.$router.back();
      } catch (error) {
        this.notify('error', {
          message: this.$t(error?.message?.trim() || 'invalidData')
        });
      } finally {
        this.loading = false;
      }
    },
    async duplicateCode() {
      try {
        this.copying = true;
        this.$ls.set('confirm-leave', true);
        const input = {
          status: 'DRAFT',
          codes: this.codes?.map(item => {
            delete item.isPlaySpeech;
            delete item.contentOverLimit;
            return item;
          }),
          title: `${this.title}_Copy`,
          code_type: 'voice_code',
          sym_size: this.size,
          sym_ec_level: this.ecLevel,
          category_id: this.categoryId,
          linkURL: this.linkURL,
          thumbnail: this.thumbnail
        };
        await this.$s.api.page.createCodes(this.pageId, input);
        await this.notify('success', {
          message: this.$t('DuplicateSuccess')
        });
        this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes`);
      } catch (error) {
        console.error(error);
      } finally {
        this.copying = false;
      }
    },
    notify(type = 'success', options = { message: '' }) {
      return new Promise(resolve => {
        setTimeout(() => {
          this.$notification[type](options);
        }, 100);
        resolve(1);
      });
    },
    previewQRCode() {
      const data = {
        code_type: 'voice_code',
        codes: this.codes?.map(item => {
          delete item.isPlaySpeech;
          delete item.contentOverLimit;
          delete item.ragt;
          return item;
        }),
        linkURL: this.linkURL,
        sym_size: this.size,
        sym_ec_level: this.ecLevel
      };
      this.$refs.previewQRRef.handlePreview(data);
    },
    cutStringByPatternAndLimit({ text, lang = 'eng' }) {
      const objMapping = {
        vie: { limit: 1000, pattern: '.' },
        jpn: { limit: 500, pattern: '。' },
        other: { limit: 1000, pattern: '.' }
      };
      const obj = objMapping[lang] || objMapping.other;
      let newText = text,
        listStr = [];
      while (newText) {
        const subStrByLimit = newText.substring(0, obj.limit);
        const lastIndexByPattern = subStrByLimit.lastIndexOf(obj.pattern);
        if (lastIndexByPattern != -1) {
          newText = newText.substring(lastIndexByPattern + 1, newText.length);
          listStr = [...listStr, subStrByLimit.substring(0, lastIndexByPattern + 1)];
        } else {
          newText = newText.substring(obj.limit, newText.length);
          listStr = [...listStr, subStrByLimit];
        }
      }
      return listStr;
    },
    async getSynthesizeSpeech(text, lang, ssml, type, gender) {
      let listFuture = [],
        listStr = [],
        listControllers = [];
      if (type === 'ssml') {
        listStr = await this.$s.api.sound.cuttingSsmlIntoSentence(ssml.text);
      } else {
        let textContentMapping = {
          jpn: text
            .replace(/\n/gim, '。')
            .replace(/。\s{1,}/gim, '。')
            .replace(/。{2,}/gim, '。')
            .replace(/、{2,}/gim, '、')
            .replace(/\u3000{1,}/gim, '、')
            .replace(/、。/gim, '。')
            .replace(/。、/gim, '。'),
          other: text
        };
        let textContent = textContentMapping[lang] || textContentMapping.other;
        listStr = this.cutStringByPatternAndLimit({
          text: textContent,
          lang: lang
        });
      }
      const payload = {
        sentence: '',
        gender,
        language: lang,
        textType: type,
        engine: this.codeDetail.speech.engine,
        options: {
          lexicon: this.currentProject?.lexicon?.length
            ? this.currentProject?.lexicon
            : this.page.lexicon
        }
      };
      listFuture = listStr.map(item => {
        const controller = new AbortController();
        listControllers.push(controller);
        return this.$s.api.sound.getSynthesizeSpeechStream(
          { ...payload, sentence: item },
          controller
        );
      });
      const data = await Promise.allSettled(listFuture);
      const bufferArray = data.reduce((arr, item) => {
        if (item.value) {
          arr = [...arr, ...item.value.mp3Stream.data];
        }
        return arr;
      }, []);
      const audioBlob = new Blob([new Uint8Array(bufferArray)], {
        type: 'audio/mp3'
      });
      const url = URL.createObjectURL(audioBlob);
      return url;
    },
    handlePublicCode() {
      this.updateCodeById({ ispublic: true, status: this.status });
    },
    handlePrivateCode() {
      this.updateCodeById({ ispublic: false, status: this.status });
    },
    async updateCodeById(input) {
      const successNotifyMessage = {
        true: this.$t('This code is public.'),
        false: this.$t('This code is private.')
      };
      const errorNotifyMessage = {
        true: this.$t('This code public failed.'),
        false: this.$t('This code private failed.')
      };
      try {
        this.publicLoading = true;
        const data = await this.$s.api.page.editCodeById(this.pageId, this.codeId, input);
        this.updateCode(data);
        this.$notification.success({
          message: successNotifyMessage[input.ispublic]
        });
        this.ispublic = input.ispublic;
      } catch (error) {
        console.log(error);
        this.$notification.error({
          message: errorNotifyMessage[input.ispublic]
        });
      } finally {
        this.publicLoading = false;
      }
    },
    checkContentOverLimit(code) {
      if (parseInt(this.size) == 0 || parseInt(this.size) == 1)
        // too small to cut
        return false;
      const MAX_LEN =
        (symSizeLength(this.size) - dbLength(this.linkURL)) / this.codes.length -
        subStringSpecialLangCase(code.lang, this.size);
      return dbLength(code.text) > MAX_LEN;
    },
    checkAndShowIfContentLimitCodes() {
      //check limit
      if (this.codes.length) {
        for (let i = 0; i < this.codes.length; i++) {
          this.codes[i].contentOverLimit = this.checkContentOverLimit(this.codes[i]);
        }
      }
      // emit the array to be reactive
      this.codes.push({});
      this.codes.pop();
    },
    handleComposeNewMessage() {
      const { title, codes } = this.codeDetail;
      const langMapping = { jpn: 'ja', eng: 'en', kor: 'ko', zho: 'zh' };
      this.$ls.set('uv-new-message-content', {
        title,
        localized_messages: codes.map(item => ({
          content: item.text,
          lang: langMapping[item.lang] || langMapping.eng,
          title
        })),
        attachment: [
          ...(this.thumbnail?.url ? [{ type: 'picture', url: this.thumbnail?.url }] : []),
          ...(this.linkURL ? [{ type: 'url', url: this.linkURL }] : [])
        ],
        qrcode: { link: this.codeDetail?.preview_qrcode || '' },
        code_id: this.codeId
      });
      this.$router.push({ path: '/message/compose' });
    },
    async deleteCode() {
      try {
        this.loading = true;
        await this.$s.api.page.deleteCodeDraftById(this.pageId, this.codeId);
        this.$notification.success({
          message: this.$t('DeletedSuccess')
        });
        this.$ls.set('confirm-leave', true);
        this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes`);
      } catch (error) {
        this.$notification.error({
          message: this.$t('Error'),
          description: error.message,
          duration: 3
        });
      } finally {
        this.loading = false;
      }
    },
    async handleCopyCodes({ selectedPageId, resolve, reject }) {
      try {
        await this.$s.api.page.copyCodeToAnotherPage(selectedPageId, [this.codeDetail.id]);
        this.$notification.success({
          message: this.$t('Copy code to page successfully', {
            name: this.page.name
          })
        });
        resolve(1);
        this.choosePageModalVisible = false;
      } catch (error) {
        this.$notification.error({
          message: this.$t('Error'),
          description: error.message,
          duration: 3
        });
        reject(error);
      }
    },
    async handleMoveCodes({ selectedPageId, resolve, reject }) {
      try {
        await this.$s.api.page.moveCodeToAnotherPage(selectedPageId, [this.codeDetail.id]);
        this.$notification.success({
          message: this.$t('Move code to page successfully', {
            name: this.page.name
          })
        });
        resolve(1);
        this.choosePageModalVisible = false;
        this.$router.push(`/projects/${this.projectId}/pages/${this.pageId}/codes`);
      } catch (error) {
        this.$notification.error({
          message: this.$t('Error'),
          description: error.message,
          duration: 3
        });
        reject(error);
      }
    },
    handleCopyCodeOpening() {
      this.choosePageModalVisible = true;
      this.pageChoosingActionType = 'copy';
    },
    handleMoveCodeOpening() {
      this.choosePageModalVisible = true;
      this.pageChoosingActionType = 'move';
    }
  }
};
